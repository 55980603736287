import { GetItemPropsOptions } from "downshift";

import { Theme } from "constants/enum";

export interface RegionType {
    name: string;
    alpha2: string;
    phoneCode: string;
    phoneMask: string;
}

export interface RegionsProps {
    regions: RegionType[];
    getItemProps: (options: GetItemPropsOptions<RegionType>) => any;
    theme: Theme;
}

export enum RegionTypes {
    GLOBAL = "global",
}
