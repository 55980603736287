import * as yup from "yup";

import { MIN_PHONE_LETTERS } from "constants/enum";
import { validEmail } from "constants/regexps";

import { MIN_LETTERS_NAME, MAX_LETTERS_NAME, REG_EXP_NAME } from "./constants";
import { BookingFormContentType } from "./BookAppointmentFullForm.types";

export const getValidationSchema = ({
    withInstagram,
    withoutPhone = false,
    cityTypeError,
    firstNameTypeError,
    lastNameTypeError,
    instagramUserTypeError,
    nameMaxError,
    nameMinError,
    phoneNumberFormatError,
    // phoneMask,
    phoneNumberTypeError,
    emailFormatError,
    emailTypeError,
    checkboxSpecialOffersError,
    checkboxAcceptError,
    isFrance,
    isUs,
}: BookingFormContentType) => {
    // const numberOfDigitsWithoutSpaces = phoneMask?.match(/\S/g)?.length;
    // const numberOfSpaces = (phoneMask || "").split(" ")?.length - 1;
    // const numberCountWithSpacesOfPhone = numberOfDigitsWithoutSpaces
    //     ? numberOfDigitsWithoutSpaces + numberOfSpaces
    //     : MIN_PHONE_LETTERS;

    return yup.object().shape({
        city: yup.string().required(cityTypeError),

        firstName: yup
            .string()
            .matches(REG_EXP_NAME, firstNameTypeError)
            .max(MAX_LETTERS_NAME, nameMaxError)
            .min(MIN_LETTERS_NAME, nameMinError)
            .required(firstNameTypeError)
            .typeError(firstNameTypeError),

        lastName: yup
            .string()
            .matches(REG_EXP_NAME, lastNameTypeError)
            .max(MAX_LETTERS_NAME, nameMaxError)
            .min(MIN_LETTERS_NAME, nameMinError)
            .required(lastNameTypeError)
            .typeError(lastNameTypeError),

        ...(withInstagram
            ? {
                  instagramUser: yup
                      .string()
                      .max(MAX_LETTERS_NAME, nameMaxError)
                      .min(MIN_LETTERS_NAME, nameMinError)
                      .required(instagramUserTypeError)
                      .typeError(instagramUserTypeError),
              }
            : null),

        ...(!withoutPhone
            ? {
                  phone: yup
                      .string()
                      .trim()
                      .min(
                          MIN_PHONE_LETTERS,
                          phoneNumberFormatError(MIN_PHONE_LETTERS)
                      )
                      .matches(/^[0-9\s]*$/, phoneNumberTypeError)
                      .required(phoneNumberTypeError),
              }
            : null),
        // .min(
        //     numberCountWithSpacesOfPhone,
        //     phoneNumberFormatError(
        //         numberOfDigitsWithoutSpaces || MIN_PHONE_LETTERS
        //     )
        // )
        // .test(
        //     "length",
        //     phoneNumberFormatError(
        //         numberOfDigitsWithoutSpaces || MIN_PHONE_LETTERS
        //     ),
        //     (val = "") =>
        //         val.replace(/\s/g, "").length ===
        //         numberOfDigitsWithoutSpaces
        // )
        // .required(phoneNumberTypeError)
        // .typeError(phoneNumberTypeError),

        email: yup
            .string()
            .matches(validEmail, emailFormatError)
            .required(emailTypeError)
            .trim()
            .typeError(emailTypeError),

        ...(!isUs && {
            hasSpecialOffers: yup
                .bool()
                .oneOf([false, true], checkboxSpecialOffersError),
        }),

        isAccept: yup.bool().oneOf([true], checkboxAcceptError),
        ...(isFrance
            ? {
                  franceAcceptFirstVisitPay: yup
                      .bool()
                      .oneOf([true], checkboxAcceptError),
              }
            : null),
    });
};
