import { FC, useState, FocusEvent } from "react";
import Downshift from "downshift";
import classnames from "classnames/bind";
import {
    Flex,
    Box,
    Icon,
    ChevronUpOutlineIcon,
} from "@smile2impress/components";

import { CountryCode } from "constants/enum";
import { Input } from "components/Input";
import {
    Regions,
    RegionType,
} from "components/PhoneRegionsInput/components/Regions";
import { GTMEvents } from "services/AnaliticsService/events";
import { sendGTMEvent } from "services/AnaliticsService/sendGoogleAnalitic";
import { FormTestID } from "components/BookAppointmentFullForm/tests/types/ids";

import { getRegion, sortRegions } from "./helpers";
import { PhoneSelectProps } from "./PhoneSelect.types";
import styles from "./PhoneSelect.module.scss";

const cx = classnames.bind(styles);

export const PhoneSelect: FC<PhoneSelectProps> = ({
    version,
    theme,
    onSwitchRegion,
    regions,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isInputWasChanged, setIsInputWasChanged] = useState(false);

    const handleCountry = (value: RegionType | null) => {
        if (value) {
            onSwitchRegion(value);
            sendGTMEvent(GTMEvents.CHANGE_COUNTRY_CODE_GTM_EVENT);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        setIsFocused(false);

        if (isInputWasChanged) {
            sendGTMEvent(GTMEvents.CHANGE_INPUT_COUNTRY_CODE_GTM_EVENT);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleKeyDown = () => {
        setIsInputWasChanged(true);
    };

    const initialRegion = getRegion(regions, CountryCode[version]);

    return (
        <Flex className={cx("root")} mr={["10px", "24px", "24px"]}>
            <Downshift
                initialSelectedItem={initialRegion}
                onChange={handleCountry}
                onInputValueChange={() => {
                    setIsFocused(false);
                }}
                itemToString={(item) =>
                    item ? item.phoneCode.replace(/\\/g, "") : ""
                }
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    selectedItem,
                }) => {
                    return (
                        <div>
                            <Box
                                style={{
                                    backgroundImage: `url("/images/icons/countries/${
                                        selectedItem
                                            ? selectedItem.alpha2.toLowerCase()
                                            : initialRegion?.alpha2.toLowerCase()
                                    }.svg")`,
                                }}
                                className={cx("icon-country")}
                                top={["15px", "16px", "10px"]}
                            />

                            <Input
                                {...getInputProps()}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                onKeyDown={handleKeyDown}
                                placeholder=""
                                pl="4"
                                w="12"
                                tabIndex={-1}
                                isActiveMargin={true}
                                dataTestId={FormTestID.COUNTRY_CODE}
                            />

                            <Icon
                                as={ChevronUpOutlineIcon}
                                position="absolute"
                                top={["15px", "16px", "10px"]}
                                left="78px"
                                pointerEvents="none"
                                transition=".2s ease-in"
                                {...((isOpen || isFocused) && {
                                    transform: "rotate(180deg)",
                                })}
                            />

                            {(isOpen || isFocused) && (
                                <Box
                                    {...getMenuProps()}
                                    className={cx("modal")}
                                >
                                    <Regions
                                        theme={theme}
                                        getItemProps={getItemProps}
                                        regions={
                                            isFocused
                                                ? sortRegions(regions)
                                                : regions.filter(
                                                      (item) =>
                                                          !inputValue ||
                                                          item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                  inputValue.toLowerCase()
                                                              ) ||
                                                          item.phoneCode.includes(
                                                              inputValue.toLowerCase()
                                                          )
                                                  )
                                        }
                                    />
                                </Box>
                            )}
                        </div>
                    );
                }}
            </Downshift>
        </Flex>
    );
};
