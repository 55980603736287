import Fuse from "fuse.js";

export const getFilteredCities = <T>(
    city: string,
    cities: T[],
    keys: string[]
) => {
    const options: Fuse.IFuseOptions<T> = {
        keys,
        threshold: 0.3,
    };

    const fuse = new Fuse(cities, options);

    return fuse.search(city);
};
