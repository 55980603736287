import { FC, useState, useEffect } from "react";
import classnames from "classnames/bind";
import Fuse from "fuse.js";
import {
    Heading,
    Text,
    Icon,
    ChevronDownOutlineIcon,
    MagnifyingGlassOutlineIcon,
} from "@smile2impress/components";
import { ActionMeta, MultiValue, SingleValue } from "react-select";

import { useWindowDimensions } from "hooks/useWindowDimensions";
import { Language, Theme, WINDOW_SIZE } from "constants/enum";
import { getOptGroupByOptions } from "utils/getOptGroupByOptions";
import { getFilteredCities } from "utils/getFilteredCities";
import { ModalSheet } from "components/ModalSheet";

import { SelectWithClinics } from "./components/SelectWithClinics";
import { filterOption } from "./helpers/filterOption";
import { getAddressByCinic } from "./helpers/getAddressByClinic";
import {
    SelectWithSearchProps,
    OptionProps,
    FilterOption,
} from "./SelectWithSearch.types";
import styles from "./SelectWithSearch.module.scss";
import { clearValueSelect } from "./helpers/cleatValueSelect";

const cx = classnames.bind(styles);

export const SelectWithSearch: FC<SelectWithSearchProps> = ({
    inputId,
    options,
    className,
    errorText,
    placeholder,
    name,
    isOpenModal,
    setOpenModal,
    setFieldTouched,
    value,
    language = Language.SPAIN,
    onChange,
    onBlur,
    customSortOptions,
    theme = Theme.DARK,
    title,
    submitRef,
    clinics,
    isShowClinics,
    selectedClinic,
    onChangeClinic,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [fuzzyOptions, setFuzzyOptions] = useState<
        Fuse.FuseResult<OptionProps>[]
    >([]);
    const { width } = useWindowDimensions();
    const isMobileView = width <= WINDOW_SIZE.DESKTOP;

    const optionsList = Object.values(getOptGroupByOptions(options));

    let optionsListCustomSorted;

    if (customSortOptions) {
        optionsListCustomSorted = customSortOptions(optionsList);
    }

    const handleChange = (
        option:
            | MultiValue<{
                  isHide: boolean;
              }>
            | SingleValue<{
                  isHide: boolean;
              }>,
        _: ActionMeta<{
            isHide: boolean;
        }>
    ) => {
        if (option) {
            onChange(option as OptionProps);
        }
    };

    const handleInputChange = (newValue: string) => {
        const results = getFilteredCities<OptionProps>(newValue, options, [
            "label",
        ]);

        setFuzzyOptions(newValue ? results : []);
        setInputValue(newValue);
    };

    const handleClearInputValue = () => {
        setInputValue("");
        onChange({ value: "", label: "" });
        clearValueSelect(placeholder || "");
    };

    const filterOptionsWithSmart = (props: FilterOption, inputValue: string) =>
        filterOption(
            props,
            inputValue,
            fuzzyOptions.map((opt) => opt?.item?.label)
        );

    const toggleOpenModal = () => {
        setOpenModal((prevState) => !prevState);
        inputId && setFieldTouched(inputId, true);

        if (!isShowClinics) {
            callbackMenuOpen();
        }
    };

    const callbackMenuOpen = () => {
        setIsMenuOpen(true);
    };

    const callbackMenuClose = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (isOpenModal && isMobileView) {
            document.body.style.touchAction = "none";
            document.body.style.overflowY = "hidden";

            if (submitRef?.current) {
                submitRef.current.style.opacity = "0";
            }
        } else {
            document.body.style.touchAction = "pan-y";
            document.body.style.overflowY = "auto";

            if (submitRef?.current) {
                submitRef.current.style.opacity = "1";
            }
        }
    }, [isOpenModal, isMobileView]);

    useEffect(() => {
        if (isMobileView) {
            callbackMenuOpen();
        }
    }, []);

    return (
        <div className={cx("root", className, `theme_${theme}`)}>
            <button
                type="button"
                onClick={toggleOpenModal}
                className={cx("button-select-sities", {
                    "button-select-sities_error": errorText,
                    "button-select-sities_has-value": Boolean(value),
                })}
            >
                <Icon
                    className={cx("icon-search")}
                    as={MagnifyingGlassOutlineIcon}
                />

                <Icon
                    className={cx("icon-arrow", {
                        "icon-arrow_active": !isMenuOpen,
                    })}
                    as={ChevronDownOutlineIcon}
                />

                <Text textStyle="body" as="span">
                    {value?.label || placeholder}
                </Text>
                {value && placeholder && (
                    <label
                        className={cx("placeholder", {
                            placeholder_light: theme === Theme.LIGHT,
                            "placeholder_has-value": Boolean(value),
                        })}
                    >
                        {placeholder}
                    </label>
                )}
            </button>

            {isMobileView && (
                <ModalSheet
                    isOpen={isOpenModal}
                    hasValue={Boolean(value)}
                    onClose={toggleOpenModal}
                >
                    <Heading as="h2" textStyle="title2" mb={1}>
                        {title}
                    </Heading>
                    <SelectWithClinics
                        inputId={inputId}
                        instanceId={name}
                        isMenuOpen={isMenuOpen}
                        isMobileView={isMobileView}
                        value={value}
                        clinics={clinics}
                        selectedClinic={selectedClinic}
                        isOpenModal={isOpenModal}
                        theme={theme}
                        language={language}
                        placeholder={placeholder}
                        onChangeClinic={onChangeClinic}
                        isShowClinics={isShowClinics}
                        autoFocus={true}
                        options={optionsListCustomSorted || optionsList}
                        className={cx("only-mobile")}
                        inputValue={inputValue}
                        filterOption={filterOptionsWithSmart}
                        onMenuOpen={callbackMenuOpen}
                        onMenuClose={callbackMenuClose}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleClearInputValue={handleClearInputValue}
                        handleBlur={onBlur}
                    />
                </ModalSheet>
            )}

            <SelectWithClinics
                inputId={inputId}
                instanceId={name}
                isMenuOpen={isMenuOpen}
                value={value}
                errorText={errorText}
                language={language}
                isOpenModal={isOpenModal}
                clinics={clinics}
                placeholder={placeholder}
                selectedClinic={selectedClinic}
                onChangeClinic={onChangeClinic}
                options={optionsListCustomSorted || optionsList}
                theme={theme}
                isShowClinics={isShowClinics}
                className={cx("only-desktop")}
                inputValue={inputValue}
                filterOption={filterOptionsWithSmart}
                handleClearInputValue={handleClearInputValue}
                onMenuOpen={callbackMenuOpen}
                onMenuClose={callbackMenuClose}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                handleBlur={onBlur}
            />

            {errorText && (
                <Text
                    textStyle="body"
                    mt="1"
                    textAlign="left"
                    color={
                        theme === Theme.LIGHT
                            ? "text.accentInverse"
                            : "text.accent"
                    }
                >
                    {errorText}
                </Text>
            )}

            <Text
                textStyle="caption"
                color="text.secondary"
                pt="1"
                display={!isMobileView && isShowClinics ? "none" : "display"}
            >
                {getAddressByCinic(clinics, selectedClinic)}
            </Text>
        </div>
    );
};
