import { useEffect, useState } from "react";

import { fetchCities, CityType } from "api/location";
import { CountryVersion } from "constants/enum";
import { sortCities } from "utils/sortCities";
import { throwException, SentryErrorTypes } from "utils/errorLogger";

const normalizeLabels = (cities: CityType[]) =>
    cities.map((city) => {
        if (city.label) {
            city.title = city.label;
        }

        return city;
    });

export const useFetchCities = (version: CountryVersion) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState<CityType[]>([]);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                setIsLoading(true);

                const cities = await fetchCities(version);

                if (cities) {
                    setCities(sortCities(normalizeLabels(cities), "title"));
                }
            } catch (error) {
                throwException(SentryErrorTypes.REASON_FOR_GET_CITIES);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCitiesData();
    }, [version]);

    return {
        cities,
        isLoading,
    };
};
