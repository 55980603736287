export const clearValueSelect = (placeholder: string) => {
    const container = document.querySelector<HTMLDivElement>(
        "[class*=singleValue]"
    );
    const input = document.querySelector<HTMLInputElement>(
        "#react-select-city-input"
    );

    if (container && placeholder) {
        container.textContent = placeholder;
        input?.focus();
    }
};
