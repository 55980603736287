import { FC, useRef, useState, ChangeEvent } from "react";
import { useRouter } from "next/router";
import classnames from "classnames/bind";
import { Form, Formik } from "formik";
import useTranslation from "next-translate/useTranslation";
import { Button, Link, Checkbox } from "@smile2impress/components";

import { ClinicType } from "api/location";
import {
    COOKIES,
    FIELDS,
    CountryPhoneCode,
    CountryVersion,
    Theme,
    ThirtyMinutesInSeconds,
    CountryCode,
} from "constants/enum";
import { checkIsFromTeenPage } from "services/TeensService";
import { convertQueryStringToQueryObject } from "utils/queryString";
import { getCookiesValueByName, setCookie } from "utils/cookie";
import { Input } from "components/Input";
import { SelectWithSearch } from "components/SelectWithSearch";
import { isHasCombinedClinics } from "components/SelectWithSearch/helpers/isHasCombinedClinics";
import { PhoneRegionsInput } from "components/PhoneRegionsInput";
import { sanitizeHtml } from "utils/sanitizeHtml";
import { getCookieName } from "utils/getCookieName";
import { FormTestID } from "components/BookAppointmentFullForm/tests/types/ids";

import content from "../../content";
import { getValidationSchema } from "../../validation";
import {
    clearPhone,
    convertCitiesToOptions,
    getCityOption,
    getClinicsByCity,
    sortClinicsByFlagships,
} from "../../helpers";
import {
    BookAppointmentFullFormFields,
    BookingFormContentType,
} from "../../BookAppointmentFullForm.types";
import { FullFormProps } from "./FullForm.types";
import styles from "./FullForm.module.scss";

const cx = classnames.bind(styles);

export const FullForm: FC<FullFormProps> = ({
    version,
    language,
    isLoading,
    onFormSubmit,
    isActionFixed,
    citiesByVersion,
    withInstagram,
    defaultCity,
    theme = Theme.DARK,
    withoutPhone,
}) => {
    const { t } = useTranslation("common-form");
    const [isOpenModal, setOpenModal] = useState(false);
    const submitRef = useRef<HTMLButtonElement>(null);
    const router = useRouter();
    const [
        isOnceTeenClinicFromTeenPageState,
        setIsOnceTeenClinicFromTeenPageState,
    ] = useState(false);
    const [phoneMask, setPhoneMask] = useState<string>();
    const [isShowClinicsState, setShowClinicsState] = useState(false);
    const contentByVersion = content[language](t, { version });

    const citiesOptions = convertCitiesToOptions(citiesByVersion);
    const isFromTeensRoute =
        Boolean(router.asPath) && checkIsFromTeenPage(router.asPath);
    const isFrance = version === CountryVersion.FRANCE;
    const isEs = version === CountryVersion.SPAIN;
    const isUs = version === CountryVersion.US;

    const searchQueryObject =
        typeof window !== "undefined"
            ? convertQueryStringToQueryObject(window?.location?.search)
            : {};

    const cityObj = getCityOption(
        citiesOptions,
        defaultCity || searchQueryObject?.location
    );

    let defaultClinicId;

    if (cityObj?.value) {
        const clinics = getClinicsByCity(citiesByVersion, cityObj.value);
        defaultClinicId = clinics[0].id;
    }

    const firstNameFromCookie = decodeURIComponent(
        getCookiesValueByName(COOKIES.FIRST_NAME_BUFFER) ||
            getCookiesValueByName(COOKIES.FIRST_NAME)
    );
    const lastNameFromCookie = decodeURIComponent(
        getCookiesValueByName(COOKIES.LAST_NAME_BUFFER) ||
            getCookiesValueByName(COOKIES.LAST_NAME)
    );
    const emailFromCookie = decodeURIComponent(
        getCookiesValueByName(COOKIES.EMAIL_BUFFER) ||
            getCookiesValueByName(COOKIES.EMAIL)
    );
    const phoneFromCookie = decodeURIComponent(
        getCookiesValueByName(COOKIES.PHONE_BUFFER)
    );

    const bookForm: BookAppointmentFullFormFields = {
        city: cityObj?.value || "",
        clinic: defaultClinicId?.toString() || "",
        firstName: firstNameFromCookie || "",
        lastName: lastNameFromCookie || "",
        phone: phoneFromCookie || "",
        country: CountryPhoneCode[version],
        email: emailFromCookie || "",
        hasSpecialOffers: false,
        isAccept: false,
        ...(withInstagram && { instagramUser: "" }),
        ...(version === CountryVersion.FRANCE && {
            franceAcceptFirstVisitPay: false,
        }),
    };

    const firstNameRef = useRef<HTMLInputElement>(null);

    const {
        cityPlaceholder,
        firstNamePlaceholder,
        lastNamePlaceholder,
        phoneNumberPlaceholder,
        emailPlaceholder,
        checkboxSpecialOffersPlaceholder,
        checkboxSpecialOffersPlaceholder2,
        checkboxAcceptPlaceholder,
        checkboxAcceptInstagramPlaceholder,
        checkboxFrFirstVisitAlertPlaceholder,
        action,
        instagramUserPlaceholder,
        titleModal,
    } = contentByVersion as BookingFormContentType;

    const handleSetRegion = (mask: string) => {
        setPhoneMask(mask);
    };

    const handleChangeClinic =
        (
            onChange: (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined
            ) => void
        ) =>
        ({ id, type }: { id: string; type: ClinicType }) => {
            onChange(
                "clinic",
                type === ClinicType.Teens &&
                    id.split("-")[1] !== ClinicType.Teens
                    ? `${id}-teens`
                    : id
            );
        };

    const handleSubmit = async ({
        city,
        country,
        firstName,
        lastName,
        phone,
        email,
        hasReminderAndMarketingStatus,
        hasReminderStatus,
        hasSpecialOffers,
        instagramUser,
        clinic,
    }: BookAppointmentFullFormFields) => {
        if (isLoading) return;
        const dataContact = {
            city,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            phone: withoutPhone ? "" : clearPhone(country + phone),
            email: email.trim(),
            hasReminderAndMarketingStatus,
            hasReminderStatus,
            hasSpecialOffers,
            clinic,
            instagramUser: instagramUser?.trim(),
        } as BookAppointmentFullFormFields;

        onFormSubmit(dataContact);
    };

    const getHasShowClinics = ({ value }: { value: string }) => {
        const clinics = getClinicsByCity(citiesByVersion, value);

        const isOnceTeenClinicFromTeenPage =
            clinics.length === 1 &&
            clinics[0].clinicType === ClinicType.Combined &&
            isFromTeensRoute;

        return (
            clinics &&
            !isOnceTeenClinicFromTeenPage &&
            (clinics?.length > 1 || isHasCombinedClinics(clinics))
        );
    };

    const setClinic = (
        value: string,
        onChangeField: (field: string, value: string) => void
    ) => {
        const clinics = getClinicsByCity(citiesByVersion, value);
        const clinicsForList = sortClinicsByFlagships(clinics);

        const currentClinic = clinics.find(
            ({ id }) => id === parseInt(searchQueryObject?.clinicId, 10)
        );

        if (isOnceTeenClinicFromTeenPageState) {
            onChangeField(
                "clinic",
                `${currentClinic?.id || clinics[0].id}-teens`
            );
        } else if (clinics.length >= 1) {
            onChangeField("clinic", `${currentClinic?.id || clinics[0].id}`);
        }

        const isOnceTeenClinicFromTeenPage =
            clinicsForList.length === 1 &&
            clinicsForList[0].clinicType === ClinicType.Combined &&
            isFromTeensRoute;

        const isShowClinics =
            clinicsForList &&
            !isOnceTeenClinicFromTeenPage &&
            (clinicsForList?.length > 1 ||
                isHasCombinedClinics(clinicsForList));

        setShowClinicsState(isShowClinics);

        setIsOnceTeenClinicFromTeenPageState(isOnceTeenClinicFromTeenPage);
    };

    const validationOptions = {
        ...contentByVersion,
        phoneMask,
        withInstagram,
        withoutPhone,
        isFrance,
        isUs,
    };

    return (
        <Formik
            validationSchema={getValidationSchema(validationOptions)}
            initialValues={bookForm}
            onSubmit={handleSubmit}
        >
            {({
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                touched,
                errors,
                values,
                isValid,
            }) => {
                const onChangeClinic = handleChangeClinic(setFieldValue);

                const onHandleChange = (e: ChangeEvent<any>) => {
                    const currentCookie = getCookieName(e.target.name);

                    if (currentCookie) {
                        setCookie(currentCookie, e.target.value, {
                            path: `/${CountryCode[version]}`,
                            "max-age": ThirtyMinutesInSeconds,
                        });
                    }

                    handleChange(e);
                };

                return (
                    <Form onSubmit={handleSubmit} data-testid={FormTestID.FORM}>
                        <Input
                            placeholder={firstNamePlaceholder}
                            name={FIELDS.FIRST_NAME}
                            key={FIELDS.FIRST_NAME}
                            ref={firstNameRef}
                            dataTestId={FIELDS.FIRST_NAME}
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={onHandleChange}
                            theme={theme}
                            errorText={
                                (touched.firstName || !!firstNameFromCookie) &&
                                errors.firstName
                                    ? errors.firstName
                                    : undefined
                            }
                        />

                        <Input
                            placeholder={lastNamePlaceholder}
                            name={FIELDS.LAST_NAME}
                            key={FIELDS.LAST_NAME}
                            dataTestId={FIELDS.LAST_NAME}
                            value={values.lastName}
                            onBlur={handleBlur}
                            onChange={onHandleChange}
                            theme={theme}
                            errorText={
                                (touched.lastName || !!lastNameFromCookie) &&
                                errors.lastName
                                    ? errors.lastName
                                    : undefined
                            }
                        />

                        {withInstagram && (
                            <Input
                                placeholder={instagramUserPlaceholder}
                                name={FIELDS.INSTAGRAM_USER}
                                dataTestId={FIELDS.INSTAGRAM_USER}
                                key={FIELDS.INSTAGRAM_USER}
                                value={values.instagramUser || ""}
                                onBlur={handleBlur}
                                onChange={onHandleChange}
                                theme={theme}
                                errorText={
                                    touched.instagramUser &&
                                    errors.instagramUser
                                        ? errors.instagramUser
                                        : undefined
                                }
                            />
                        )}

                        <Input
                            placeholder={emailPlaceholder}
                            name={FIELDS.EMAIL}
                            key={FIELDS.EMAIL}
                            dataTestId={FIELDS.EMAIL}
                            type="email"
                            value={values.email}
                            onBlur={handleBlur}
                            onChange={onHandleChange}
                            theme={theme}
                            errorText={
                                (touched.email || !!emailFromCookie) &&
                                errors.email
                                    ? errors.email
                                    : undefined
                            }
                        />

                        {!withoutPhone && (
                            <PhoneRegionsInput
                                defaultRegion={CountryPhoneCode[version]}
                                placeholder={phoneNumberPlaceholder}
                                name={FIELDS.PHONE}
                                key={FIELDS.PHONE}
                                nameCountry="country"
                                version={version}
                                language={language}
                                value={values.phone}
                                withoutPhone={withoutPhone}
                                onBlur={handleBlur}
                                onChange={onHandleChange}
                                onSetRegion={handleSetRegion}
                                theme={theme}
                                errorText={
                                    (touched.phone || !!phoneFromCookie) &&
                                    errors.phone
                                        ? errors.phone
                                        : undefined
                                }
                            />
                        )}

                        <SelectWithSearch
                            inputId="city"
                            placeholder={cityPlaceholder}
                            onChangeClinic={onChangeClinic}
                            selectedClinic={values.clinic}
                            className={cx("select-cities")}
                            setOpenModal={setOpenModal}
                            setFieldTouched={setFieldTouched}
                            options={citiesOptions}
                            isOpenModal={isOpenModal}
                            onChange={({ value }) => {
                                setFieldValue("city", value);
                                setClinic(value, setFieldValue);

                                const isShowClinics = getHasShowClinics({
                                    value,
                                });

                                if (!isShowClinics) {
                                    setOpenModal((state) => !state);
                                }
                            }}
                            onBlur={handleBlur}
                            theme={theme}
                            title={titleModal}
                            submitRef={submitRef}
                            isShowClinics={isShowClinicsState}
                            clinics={sortClinicsByFlagships(
                                getClinicsByCity(citiesByVersion, values.city)
                            )}
                            value={getCityOption(citiesOptions, values.city)}
                            language={language}
                            name="city"
                            key="city"
                            errorText={
                                touched.city && errors.city && !values.city
                                    ? errors.city
                                    : undefined
                            }
                        />

                        {!isUs && checkboxSpecialOffersPlaceholder && (
                            <Checkbox
                                variant="secondary"
                                mb={2}
                                name={
                                    isEs
                                        ? "hasReminderStatus"
                                        : "hasReminderAndMarketingStatus"
                                }
                                data-testid={
                                    FormTestID.CHECKBOX_REMINDER_STATUS
                                }
                                onBlur={handleBlur}
                                onChange={onHandleChange}
                            >
                                {checkboxSpecialOffersPlaceholder}
                            </Checkbox>
                        )}
                        {isEs && checkboxSpecialOffersPlaceholder2 && (
                            <Checkbox
                                variant="secondary"
                                mb={2}
                                name="hasSpecialOffers"
                                data-testid={FormTestID.CHECKBOX_SPECIAL_OFFER}
                                onBlur={handleBlur}
                                onChange={onHandleChange}
                            >
                                {checkboxSpecialOffersPlaceholder2}
                            </Checkbox>
                        )}
                        <Checkbox
                            variant="secondary"
                            mb={3}
                            data-testid={FormTestID.IS_ACCEPT}
                            name="isAccept"
                            onBlur={handleBlur}
                            onChange={onHandleChange}
                        >
                            {withInstagram ? (
                                <>
                                    {checkboxAcceptInstagramPlaceholder.text}
                                    &nbsp;
                                    <Link
                                        target={"_blank"}
                                        color={
                                            theme === Theme.DARK
                                                ? "text.link"
                                                : "text.secondaryInverse"
                                        }
                                        href={
                                            checkboxAcceptInstagramPlaceholder.link
                                        }
                                    >
                                        {
                                            checkboxAcceptInstagramPlaceholder.name
                                        }
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {checkboxAcceptPlaceholder.text}
                                    &nbsp;
                                    <Link
                                        target={"_blank"}
                                        color={
                                            theme === Theme.DARK
                                                ? "text.link"
                                                : "text.secondaryInverse"
                                        }
                                        href={checkboxAcceptPlaceholder.link}
                                    >
                                        {checkboxAcceptPlaceholder.name}
                                    </Link>
                                    &nbsp;
                                    {checkboxAcceptPlaceholder.linkPromotion &&
                                        checkboxAcceptPlaceholder.textAnd}
                                    {checkboxAcceptPlaceholder.linkPromotion && (
                                        <>
                                            &nbsp;
                                            <Link
                                                target="_blank"
                                                color={
                                                    theme === Theme.DARK
                                                        ? "text.link"
                                                        : "text.secondaryInverse"
                                                }
                                                href={
                                                    checkboxAcceptPlaceholder.linkPromotion
                                                }
                                            >
                                                {
                                                    checkboxAcceptPlaceholder.namePromotion
                                                }
                                            </Link>
                                            {checkboxAcceptPlaceholder.namePromotionEnd && (
                                                <>
                                                    &nbsp;
                                                    {
                                                        checkboxAcceptPlaceholder.namePromotionEnd
                                                    }
                                                </>
                                            )}
                                            {
                                                checkboxAcceptPlaceholder.requiredFieldSign
                                            }
                                        </>
                                    )}
                                </>
                            )}
                        </Checkbox>
                        {isFrance && checkboxFrFirstVisitAlertPlaceholder && (
                            <Checkbox
                                variant="secondary"
                                mb={2}
                                name="franceAcceptFirstVisitPay"
                                onBlur={handleBlur}
                                data-testid={FormTestID.FRANCE_ACCEPT}
                                onChange={onHandleChange}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            checkboxFrFirstVisitAlertPlaceholder
                                        ),
                                    }}
                                />
                            </Checkbox>
                        )}

                        <div
                            className={cx("mobile-action-wrapper", {
                                "mobile-action-wrapper_is-fixed": isActionFixed,
                            })}
                        >
                            <Button
                                type="submit"
                                isFullWidth
                                isLoading={isLoading}
                                data-testid={FormTestID.SUBMIT}
                                ref={submitRef}
                                variant={isValid ? "primary" : "disabled"}
                                disabled={!isValid}
                            >
                                {action}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
