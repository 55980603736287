import { Props, StylesConfig } from "react-select";

import { Theme } from "constants/enum";

export const customStyles = (
    theme: Theme,
    isMobileView?: boolean,
    hasValue?: boolean,
    errorText?: string
): StylesConfig => {
    const isThemeLight = theme === Theme.LIGHT;

    const getBorderLine = (state: Props) => {
        if (state.menuIsOpen) {
            return "1px solid #45a1f8";
        }

        if (isThemeLight) {
            return "1px solid rgba(255, 255, 255, 0.2)";
        }

        if (errorText) {
            return "1px solid rgba(234, 93, 91, 1)";
        }

        return "1px solid rgba(66, 78, 88, 0.1)";
    };

    return {
        group: () => ({
            marginBottom: 4,
            position: "relative",
        }),
        option: (_: any, state: Props) => {
            const customProps = { ...state };
            return {
                position: "relative",
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 36,
                transition: "0.2s backgroundColor linear",

                ":hover": {
                    backgroundColor: "rgba(238, 243, 244, 0.4)",
                },

                "::after": (customProps as { isSelected: any }).isSelected && {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    left: isMobileView ? "auto" : 10,
                    right: isMobileView ? 15 : "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#424e58",
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                },
            };
        },
        control: (_: any, state: Props) => ({
            border: "none",
            borderBottom: getBorderLine(state),
            minHeight: "auto",
            height: 56,
            outline: "none",
            boxShadow: "none",
            borderRadius: 0,
            transition: "0.2s borderBottom linear",
            paddingRight: 36,
            paddingLeft: 36,
        }),
        indicatorsContainer: () => ({
            display: "none",
        }),
        noOptionsMessage: (styles) => ({
            ...styles,
            color: "hsla(207, 14%, 18%, 1)",
        }),
        valueContainer: () => ({
            height: 56,
            display: "flex",
            alignItems: "flex-end",
            overflow: "hidden",
            paddingBottom: 10,
        }),
        placeholder: () => ({
            position: "absolute",
            bottom: "8px",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "24px",
            color:
                isThemeLight && !isMobileView
                    ? "#ffffff"
                    : "hsla(207, 14%, 18%, 1)",

            "@media(min-width: 1024px)": {
                fontSize: "20px",
                lineHeight: "28px",
            },
        }),
        singleValue: (styles) => ({
            ...styles,
            flexShrink: 0,
            fontWeight: "400",
            fontSize: "17px",
            lineHeight: "24px",
            margin: 0,
            color:
                isThemeLight && !isMobileView
                    ? "#ffffff"
                    : "hsla(207, 14%, 18%, 1)",
            overflow: "hidden",
            top: hasValue ? "62%" : "50%",

            "@media(min-width: 1024px)": {
                fontSize: "20px",
                lineHeight: "28px",
            },
        }),
        input: () => ({
            position: "absolute",
            bottom: 11,
            input: {
                fontWeight: "400",
                fontFamily: "inherit",
                fontSize: "17px",
                lineHeight: "24px",
                color: isThemeLight
                    ? "#ffffff !important"
                    : "hsla(207, 14%, 18%, 1) !important",
                maxWidth: 375,
            },
            "input:-webkit-autofill": {
                transition: "background-color 5000s",
                WebkitTextFillColor: isThemeLight
                    ? "#ffffff !important"
                    : "#424e58 !important",
            },
        }),
        menu: (styles) => ({
            ...styles,
            marginTop: 0,
            zIndex: 300,
            boxShadow: isMobileView
                ? "none"
                : "0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1)",
            backgroundColor: "#ffffff",

            "::after": {
                content: "''",
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: 30,
                backgroundImage: `linear-gradient(
                    180deg,
                    "#ffffff" 0%,
                    "rgba(255, 255, 255, 0)" 100%
                )`,
                transform: "rotate(-180deg)",
            },

            "::before": {
                content: "''",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                zIndex: 100,
                height: 30,
                backgroundImage: `linear-gradient(
                180deg,
                "#ffffff" 0%,
                "rgba(255, 255, 255, 0)" 100%
            )`,
            },
        }),
        menuList: (styles) => ({
            ...styles,
            maxHeight: isMobileView ? "75vh" : 300,
            minHeight: isMobileView ? "75vh" : "none",
            paddingBottom: 100,
            zIndex: 301,

            "@media(max-width: 280px)": {
                maxHeight: "80vh",
            },
        }),
    };
};
