import { RefObject, Dispatch, SetStateAction, FocusEvent } from "react";

import { Language, Theme } from "constants/enum";
import { ClinicInfo, ClinicType } from "api/location/location.types";

export type OptionProps = {
    value: string;
    label: string;

    isHide?: boolean;
};

export type OptionsWithGroups = {
    label: string;
    options: OptionProps[];
};

export interface SelectWithSearchProps {
    options: OptionProps[];
    clinics: ClinicInfo[];
    isShowClinics: boolean;
    selectedClinic: string;
    isOpenModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setFieldTouched: (field: string, touched: boolean) => void;
    onChange: (option: OptionProps) => void;
    onChangeClinic: ({ id, type }: { id: string; type: ClinicType }) => void;

    inputId?: string;
    title?: string;
    theme?: Theme;
    errorText?: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    name?: string;
    value?: OptionProps;
    language?: Language;
    submitRef?: RefObject<HTMLButtonElement>;
    customSortOptions?: (options: OptionsWithGroups[]) => OptionsWithGroups[];
    onBlur?: (e: FocusEvent<any>) => void;
}

export type FilterOption = {
    label: string;
    value: string;
    data: {
        isHide: boolean;
    };
};

export const NoOptionsMessage = {
    [Language.SPAIN]: "Sin opciones",
    [Language.CATALONIA]: "No hi ha opcions",
    [Language.ENGLISH]: "No options",
    [Language.FRANCE]: "Aucune option",
    [Language.ITALY]: "Nessuna opzione",
    [Language.PORTUGAL]: "Nessuna opzione",
    [Language.UKRAINE]: "Немає варіантів",
    [Language.GERMANY]: "Keine Optionen",
};
