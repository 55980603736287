import { LocaleData } from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import es from "i18n-iso-countries/langs/es.json";
import ca from "i18n-iso-countries/langs/ca.json";
import fr from "i18n-iso-countries/langs/fr.json";
import de from "i18n-iso-countries/langs/de.json";
import it from "i18n-iso-countries/langs/it.json";
import pt from "i18n-iso-countries/langs/pt.json";
import ua from "i18n-iso-countries/langs/uk.json";

import { Language } from "constants/enum";

export const locale: {
    [key in Language]: LocaleData;
} = {
    [Language.ENGLISH]: en,
    [Language.SPAIN]: es,
    [Language.CATALONIA]: ca,
    [Language.FRANCE]: fr,
    [Language.GERMANY]: de,
    [Language.ITALY]: it,
    [Language.PORTUGAL]: pt,
    [Language.UKRAINE]: ua,
};
