import React, { FunctionComponent } from "react";
import classnames from "classnames/bind";
import { Box, Icon, CrossThinOutlineIcon } from "@smile2impress/components";

import { ModalProps } from "./Modal.types";
import styles from "./Modal.module.scss";

const cx = classnames.bind(styles);

export const Modal: FunctionComponent<ModalProps> = ({
    children,
    onClose,
    showCloseButton = true,
    position,
    classNameBody,
}) => {
    return (
        <div className={cx("root")}>
            <div onClick={onClose} className={cx("overlay")}>
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={cx(
                        "body",
                        position && `body_${position}`,
                        classNameBody
                    )}
                >
                    {showCloseButton && (
                        <Box
                            as="button"
                            cursor="pointer"
                            onClick={onClose}
                            pos="absolute"
                            top={5}
                            right={5}
                        >
                            <Icon
                                as={CrossThinOutlineIcon}
                                boxSize={3}
                                color="text.primary"
                            />
                        </Box>
                    )}

                    {children}
                </div>
            </div>
        </div>
    );
};
