import { createContext, useContext } from "react";

import { noop } from "utils/noop";

import { RadioGroupContextProps } from "./components/RadioGroup";

export const RadioContext = createContext<RadioGroupContextProps>({
    value: "",
    onChange: noop,
});
export const RadioGroupContextProvider = RadioContext.Provider;

export const useRadioContext = () => useContext(RadioContext);
