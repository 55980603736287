import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import prices from "configs/prices.json";

import { BookingFormContentType } from "../BookAppointmentFullForm.types";
import { FormContentProps } from "./types";
import defaultContent from "./spain";

const pageContent = (
    t: Translate,
    { version }: FormContentProps
): BookingFormContentType => {
    const content = defaultContent(t, { version });

    return mergeDeepRight(content, {
        checkboxAcceptPlaceholder: {
            ...content.checkboxAcceptPlaceholder,
            textAnd: "",
        },
        checkboxFrFirstVisitAlertPlaceholder: t(
            "common-form:checkboxFrFirstVisitAlertPlaceholder",
            { price: prices.FVPrice[version] }
        ),
    });
};

export default pageContent;
