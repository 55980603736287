export enum StatusesResponseType {
    OK = "OK",
    ERROR = "ERROR",
}

export enum ErrorsResponseType {
    NOT_FOUND = "404",
    INTERNAL_SERVER_ERROR = "500",
    SPOT_ALREADY_FILLED = "spot_already_filled",
}
