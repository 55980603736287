import { FC, useEffect, useRef } from "react";
import Select, {
    OptionsOrGroups,
    GroupBase,
    PropsValue,
    StylesConfig,
} from "react-select";
import classnames from "classnames/bind";
import {
    Text,
    Icon,
    ChevronDownOutlineIcon,
    MagnifyingGlassOutlineIcon,
    CrossOutlineIcon,
} from "@smile2impress/components";

import { Theme } from "constants/enum";
import { useIsIOS } from "hooks/useIsIOS";

import { customStyles } from "../../customStyles";
import { NoOptionsMessage } from "../../SelectWithSearch.types";
import { SelectWithClinicsProps } from "./SelectWithClinics.types";
import styles from "./SelectWithClinics.module.scss";
import { ClinicList } from "../ClinicList";

const cx = classnames.bind(styles);
const TIMEOUT_BEFORE_FOCUS = 600;

export const SelectWithClinics: FC<SelectWithClinicsProps> = ({
    inputId,
    instanceId,
    isMenuOpen,
    isMobileView,
    isOpenModal,
    value,
    errorText,
    inputValue,
    placeholder,
    options,
    language,
    theme,
    isShowClinics,
    className,
    clinics,
    selectedClinic,
    filterOption,
    handleChange,
    handleInputChange,
    handleClearInputValue,
    onMenuOpen,
    onMenuClose,
    onChangeClinic,
    handleBlur,
}) => {
    const selectRef = useRef(null);
    const isOS = useIsIOS();

    useEffect(() => {
        if (!isOS && isMobileView && isOpenModal && inputId) {
            const input = document.querySelector<HTMLInputElement>(
                `#${inputId}`
            );

            if (input) {
                setTimeout(() => {
                    input?.focus();
                }, TIMEOUT_BEFORE_FOCUS);
            }
        }
    }, [isOpenModal]);

    return (
        <div className={cx("container-select", className)}>
            <Icon
                className={cx("icon-search", {
                    "icon-search_light": theme === Theme.LIGHT,
                })}
                as={MagnifyingGlassOutlineIcon}
            />

            <Icon
                className={cx("icon-arrow", {
                    "icon-arrow_active": isMenuOpen,
                    "icon-arrow_light": theme === Theme.LIGHT,
                })}
                as={ChevronDownOutlineIcon}
            />

            {inputValue || value ? (
                <button
                    type="button"
                    className={cx("button-clear")}
                    onClick={handleClearInputValue}
                >
                    <Icon as={CrossOutlineIcon} className={cx("icon-clear")} />
                </button>
            ) : null}

            {value && placeholder && (
                <Text
                    as="label"
                    textStyle="caption"
                    className={cx("placeholder", {
                        placeholder_light: theme === Theme.LIGHT,
                    })}
                >
                    {placeholder}
                </Text>
            )}

            <Select
                ref={selectRef}
                inputId={inputId}
                name={inputId}
                instanceId={instanceId}
                options={
                    options as
                        | OptionsOrGroups<
                              {
                                  isHide: boolean;
                              },
                              GroupBase<{
                                  isHide: boolean;
                              }>
                          >
                        | undefined
                }
                styles={
                    customStyles(
                        theme,
                        isMobileView,
                        Boolean(value),
                        errorText
                    ) as
                        | StylesConfig<
                              {
                                  isHide: boolean;
                              },
                              boolean,
                              GroupBase<{
                                  isHide: boolean;
                              }>
                          >
                        | undefined
                }
                value={
                    value as
                        | PropsValue<{
                              isHide: boolean;
                          }>
                        | undefined
                }
                menuIsOpen={
                    isMobileView && (!value || !isShowClinics)
                        ? true
                        : isMenuOpen
                }
                onChange={handleChange}
                onInputChange={handleInputChange}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
                {...(handleBlur && {
                    onBlur: handleBlur,
                })}
                filterOption={filterOption}
                noOptionsMessage={() => NoOptionsMessage[language]}
                blurInputOnSelect={true}
                placeholder={placeholder}
                formatGroupLabel={({ label }) => (
                    <span className={cx("label")}>{label}</span>
                )}
            />

            {isShowClinics && !isMenuOpen && (
                <ClinicList
                    clinics={clinics}
                    language={language}
                    onChange={onChangeClinic}
                    value={selectedClinic}
                />
            )}
        </div>
    );
};
