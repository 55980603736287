import { FC, useState, useEffect } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import classnames from "classnames/bind";
import { Text } from "@smile2impress/components";

import { postContact, PostVisitorContact } from "api/contacts";
import contacts from "configs/contacts.json";
import { useFetchCities } from "hooks/useFetchCities";
import { useLocationContext } from "context/locationContext";
import {
    COOKIES,
    Theme,
    TwentyFourHoursInSeconds,
    SESSION_STORAGE_KEYS,
    LOCAL_STORAGE_KEYS,
    MFA_METHODS,
    Language,
    CountryCode,
} from "constants/enum";
import {
    redirectToAppointmentPage,
    redirectToPAPage,
} from "services/RedirectService";
import { sendAnalitic } from "services/AnaliticsService";
import { setCookie, setCookieByObjectValues } from "utils/cookie";
import { VerificationModal } from "components/VerificationModal";
import { SentryErrorTypes, throwException } from "utils/errorLogger";

import {
    clearPhone,
    getCityObj,
    clearEmail,
    prepareHubspotProperties,
    getClinicsByCity,
} from "./helpers";
import { FullForm } from "./components/FullForm";
import {
    BookAppointmentFullFormFields,
    BookAppointmentFullFormProps,
    FormSubmitProps,
} from "./BookAppointmentFullForm.types";
import styles from "./BookAppointmentFullForm.module.scss";
import { FormTestID } from "./tests/types/ids";

const cx = classnames.bind(styles);

export const BookAppointmentFullForm: FC<BookAppointmentFullFormProps> = ({
    analyticId,
    className,
    additionalContactData = {},
    additionalProperties = {},
    isActionFixed,
    withInstagram,
    defaultCity,
    theme = Theme.DARK,
    landingPageName,
    withoutPhone = false,
}) => {
    const { t } = useTranslation("common-form");
    const { version, language = Language.SPAIN } = useLocationContext();
    const { asPath } = useRouter();
    const [isLoading, setLoading] = useState(false);
    const { cities } = useFetchCities(version);
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [retryInterval, setRetryInterval] = useState(60);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [isApiError, setIsApiError] = useState(false);

    useEffect(() => {
        if (showVerificationModal) {
            document.body.style.touchAction = "none";
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.touchAction = "pan-y";
            document.body.style.overflowY = "auto";
        }
    }, [showVerificationModal]);

    const onFormSubmit = async ({
        mainData: { firstName, lastName, email, phone, clinicId },
        cityTitle,
    }: FormSubmitProps) => {
        const options = {
            "max-age": TwentyFourHoursInSeconds,
            path: "/",
        };

        setCookieByObjectValues(
            {
                [COOKIES.FIRST_NAME]: firstName,
                [COOKIES.LAST_NAME]: lastName,
                [COOKIES.PHONE]: phone,
                [COOKIES.EMAIL]: email,
                [COOKIES.CLINIC_ID]: clinicId,
                ...(cityTitle && {
                    [COOKIES.CITY_NAME]: cityTitle,
                }),
            },
            options
        );

        await sendAnalitic({
            version,
            cityTitle: cityTitle.toLowerCase(),
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLocaleLowerCase(),
            email,
            phone,
        });

        if (clinicId) {
            const clinics = getClinicsByCity(cities, cityTitle);
            const { scheduleExists } =
                clinics.find(({ id }) => id === clinicId) || {};

            redirectToAppointmentPage(version, clinicId, !scheduleExists);
        }
    };

    const onButtonClick = async ({
        city: cityName,
        firstName,
        lastName,
        phone,
        email,
        hasReminderAndMarketingStatus,
        hasReminderStatus,
        hasSpecialOffers,
        instagramUser,
        clinic,
    }: BookAppointmentFullFormFields) => {
        if (isApiError) setIsApiError(false);

        setLoading(true);

        const { id: cityId, title: cityTitle } =
            getCityObj(cities, cityName) || {};
        const cleanPhone = clearPhone(phone);
        const cleanEmail = clearEmail(email);
        const clinicId = parseInt(clinic, 10);

        const mainData = {
            firstName,
            lastName,
            email: cleanEmail,
            phone: cleanPhone,
            clinicId,
        };

        const hubspotProperties = prepareHubspotProperties(
            language,
            clinic,
            asPath,
            hasReminderAndMarketingStatus,
            hasReminderStatus,
            hasSpecialOffers,
            instagramUser,
            landingPageName
        );

        const dataContact = {
            ...mainData,
            ...additionalContactData,
            properties: {
                ...hubspotProperties,
                ...additionalProperties,
            },
        } as PostVisitorContact;

        if (cityId) {
            dataContact.cityId = Number(cityId);
        }

        setPhoneNumber(cleanPhone);

        const formSubmitData = {
            mainData,
            clinic,
            cityTitle,
        };

        try {
            const { data, error } = await postContact(dataContact);

            if (!!error) throw new Error(error);

            const hasBookings = !!data?.bookings?.length;

            if (hasBookings) {
                const {
                    user: { email },
                } = data;

                return redirectToPAPage(email, language, CountryCode[version]);
            }

            const {
                user,
                mfaRequired,
                mfaMethods,
                token,
                expire,
                retryInterval: retry,
            } = data;

            if (mfaRequired && token && mfaMethods.includes(MFA_METHODS.SMS)) {
                setRetryInterval(retry);
                setShowVerificationModal(true);
                setCookie(COOKIES.VERIFICATION_TOKEN, token, {
                    expires: expire && new Date(expire).toUTCString(),
                });
                localStorage.setItem(
                    LOCAL_STORAGE_KEYS.USER_FORM_DATA,
                    JSON.stringify(user)
                );
                sessionStorage.setItem(
                    SESSION_STORAGE_KEYS.FORM_DATA,
                    JSON.stringify(formSubmitData)
                );
            } else {
                onFormSubmit(formSubmitData as FormSubmitProps);
            }
        } catch {
            setIsApiError(true);

            throwException(SentryErrorTypes.REASON_FOR_CREATE_CONTACT);
        } finally {
            setLoading(false);
        }
    };

    const onModalClose = () => {
        setShowVerificationModal(false);
        setLoading(false);
    };

    const onVerificationSuccess = () => {
        const formSubmitData = JSON.parse(
            sessionStorage.getItem(SESSION_STORAGE_KEYS.FORM_DATA) || ""
        );
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_FORM_DATA);
        onFormSubmit(formSubmitData);
    };

    return (
        <div
            data-testid={FormTestID.ROOT}
            className={cx(className, "root", `root_${theme}`)}
        >
            {cities?.length > 0 && (
                <FullForm
                    analyticId={analyticId}
                    version={version}
                    language={language}
                    defaultCity={defaultCity}
                    isLoading={isLoading}
                    onFormSubmit={onButtonClick}
                    isActionFixed={isActionFixed}
                    citiesByVersion={cities}
                    withInstagram={withInstagram}
                    withoutPhone={withoutPhone}
                    theme={theme}
                />
            )}
            {isApiError && (
                <Text color="text.accent" mt={3}>
                    {t("fullFormApiError", {
                        phone: contacts.phone[version].public,
                    })}
                </Text>
            )}
            {showVerificationModal && (
                <VerificationModal
                    phone={phoneNumber}
                    countdownStart={retryInterval}
                    onClose={onModalClose}
                    onSuccess={onVerificationSuccess}
                />
            )}
        </div>
    );
};
