import { urlsConfig } from "configs/urlsConfig";
import { pathConfig } from "navigation/pathConfig";
import {
    CountryCode,
    CountryVersion,
    Language,
    QUERY_CLINIC,
    VersionToBookingWidgetPage,
} from "constants/enum";
import {
    convertQueryObjectToQueryString,
    QueryObject,
} from "utils/queryString";

export const redirectToAppointmentPage = (
    version: CountryVersion,
    clinicId: number,
    withoutSchedule?: boolean
) => {
    const queryString = `?${QUERY_CLINIC}=${clinicId}`;
    const appointmentPage = withoutSchedule
        ? pathConfig.thanksContact[version]
        : VersionToBookingWidgetPage[version];

    window.location.href = `${appointmentPage}${encodeURI(queryString)}`;
};

export const redirectToPAPage = (
    email: string,
    lang: Language,
    country: CountryCode
) => {
    const queryObject = {
        email,
        lang,
        country,
    };

    const queryString = convertQueryObjectToQueryString(
        queryObject as QueryObject
    );

    window.location.href = `${urlsConfig.personalAreaBookingExists}?${encodeURI(
        queryString
    )}`;
};
