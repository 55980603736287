import { Translate } from "next-translate";
import { mergeDeepRight } from "ramda";

import { BookingFormContentType } from "../BookAppointmentFullForm.types";
import { FormContentProps } from "./types";
import defaultContent from "./spain";

const pageContent = (
    t: Translate,
    { version }: FormContentProps
): BookingFormContentType =>
    mergeDeepRight(defaultContent(t, { version }), {
        checkboxAcceptPlaceholder: {
            textAnd: undefined,
        },
    });

export default pageContent;
