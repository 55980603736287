import {
    FC,
    useRef,
    useState,
    useEffect,
    KeyboardEvent,
    ChangeEvent,
} from "react";
import useTranslation from "next-translate/useTranslation";
import classNames from "classnames/bind";
import {
    Heading,
    Text,
    Button,
    Box,
    Flex,
    Input,
} from "@smile2impress/components";

import { StatusesResponseType } from "api/constants";
import { retryVerifyContact, verifyContact } from "api/contacts";
import { useCountdownContext } from "context/countdownContext";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import {
    COOKIES,
    LOCAL_STORAGE_KEYS,
    MFA_ERROR_CODES,
    MFA_METHODS,
    WINDOW_SIZE,
} from "constants/enum";
import { convertSecondsToHHMMSS } from "utils/time";
import { getCookiesValueByName } from "utils/cookie";
import { Modal, ModalPosition } from "components/Modal";
import { ModalSheet } from "components/ModalSheet";

import { VerificationModalProps } from "./VerificationModal.types";
import styles from "./VerificationModal.module.scss";

const cx = classNames.bind(styles);

const ERROR_LOKALISE_KEYS = {
    [MFA_ERROR_CODES.INVALID_CODE]: "errorInvalidCode",
    [MFA_ERROR_CODES.TOKEN_NOT_FOUND]: "errorTokenNotFound",
};

export const VerificationModal: FC<VerificationModalProps> = ({
    codeLength = 4,
    phone,
    countdownStart = 60,
    onClose,
    onSuccess,
}) => {
    const { t } = useTranslation("commonVerification");
    const { width } = useWindowDimensions();
    const refArray = useRef<Array<HTMLInputElement | null>>([]);
    const isMobileView = width <= WINDOW_SIZE.DESKTOP;
    const [codeMap, setCodeMap] = useState(new Map());
    const [isCodeValid, setIsCodeValid] = useState(true);
    const [errorCode, setErrorCode] = useState("");
    const { countdownValue, setCountdownValue } = useCountdownContext();

    const postCode = async () => {
        const verificationCode = Array.from(codeMap.values()).join("");
        const user = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_KEYS.USER_FORM_DATA) || ""
        );

        if (errorCode === MFA_ERROR_CODES.TOKEN_NOT_FOUND) {
            return;
        }

        const verifyContactData = {
            user,
            token: getCookiesValueByName(COOKIES.VERIFICATION_TOKEN),
            mfaMethod: MFA_METHODS.SMS,
            destination: phone,
            confirmationCode: verificationCode,
        };
        const { status, error } = await verifyContact(verifyContactData);

        if (status === StatusesResponseType.OK) {
            setIsCodeValid(true);
            onSuccess();
        } else if (error === MFA_ERROR_CODES.TOKEN_EXPIRED) {
            onClose();
        } else {
            setIsCodeValid(false);
            setErrorCode(error);
        }
    };

    useEffect(() => {
        if (!countdownValue) {
            setCountdownValue(countdownStart);
        }
    }, []);

    useEffect(() => {
        const ifFullCode = [...codeMap.values()].every((value) =>
            Boolean(value)
        );

        if (codeMap.size === codeLength && ifFullCode) {
            postCode();
        } else {
            setIsCodeValid(true);
        }
    }, [codeMap]);

    const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        const invalidChars = ["-", "+", "e", ".", ",", " "];

        if (!key || invalidChars.includes(key)) {
            return false;
        }
    };

    const onKeyUp =
        (index: number) =>
        ({ key }: KeyboardEvent<HTMLInputElement>) => {
            if (key === "Backspace" && index >= 0) {
                // Move focus to next input
                refArray?.current?.[index - 1]?.focus();

                setCodeMap((map) => new Map(map.set(index, "")));
            }
        };

    const handleInput =
        (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            if (value?.length >= 1) {
                event.preventDefault();

                // Move focus to next input
                refArray?.current?.[index + 1]?.focus();

                setCodeMap((map) => new Map(map.set(index, value.slice(-1))));
            }
        };

    const resendCode = () => {
        const token = getCookiesValueByName(COOKIES.VERIFICATION_TOKEN);

        if (token) {
            const retryVerifyData = {
                token,
                mfaMethod: MFA_METHODS.SMS,
                destination: phone,
            };
            retryVerifyContact(retryVerifyData);
            setCodeMap(new Map());
            setIsCodeValid(true);
            setErrorCode("");
            // Move focus to initial input
            refArray?.current?.[0]?.focus();
            setCountdownValue(countdownStart);
        } else {
            onClose();
        }
    };

    const content = (
        <Box my="auto" maxW={["none", "352px"]}>
            {!isMobileView && (
                <Heading textStyle="title2" mb={1}>
                    {t("title")}
                </Heading>
            )}
            <Text textStyle="body" mb={4} color="text.secondary">
                {t("subtitle", {
                    phone,
                })}
            </Text>
            <Flex as="section" w="100%">
                {Array(codeLength)
                    .fill("")
                    .map((_, index) => (
                        <Input
                            type="number"
                            value={codeMap.get(index) || ""}
                            onChange={handleInput(index)}
                            onKeyDown={onKeyDown}
                            onKeyUp={onKeyUp(index)}
                            ref={(ref) => {
                                refArray.current[index] = ref;
                            }}
                            key={index}
                            w={8}
                            h={8}
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="bold"
                            lineHeight="33.6px"
                            border="1px solid"
                            borderColor={
                                isCodeValid ? "line.secondary" : "action.error"
                            }
                            mr={index === codeLength - 1 ? 0 : 1}
                        />
                    ))}
            </Flex>
            {!isCodeValid && (
                <Text textStyle="caption" color="action.error" pt={1}>
                    {t(
                        ERROR_LOKALISE_KEYS[
                            errorCode as keyof typeof ERROR_LOKALISE_KEYS
                        ] || "errorCommon"
                    )}
                </Text>
            )}
            <Text textStyle="caption" pt={4} mb={2} color="text.secondary">
                {t("retryTitle")}
                &nbsp;
                <Text
                    as="span"
                    textStyle="caption"
                    color="text.primary"
                    fontWeight="bold"
                >
                    {convertSecondsToHHMMSS(countdownValue)}
                </Text>
            </Text>
            <Button
                onClick={resendCode}
                variant={!!countdownValue ? "disabled" : "primary"}
                disabled={!!countdownValue}
                w="100%"
            >
                {t("retryButton")}
            </Button>
        </Box>
    );

    return isMobileView ? (
        <ModalSheet title={t("title")} isOpen={true} onClose={onClose}>
            {content}
        </ModalSheet>
    ) : (
        <Modal
            classNameBody={cx("modal")}
            position={ModalPosition.RIGHT}
            onClose={onClose}
        >
            {content}
        </Modal>
    );
};
