import { useState, useEffect } from "react";

import { checkIsIOS } from "utils/browser";

export const useIsIOS = () => {
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(checkIsIOS());
    }, []);

    return isIOS;
};
