import { FC, useState, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import cn from "classnames/bind";
import countries from "i18n-iso-countries";
import { Flex } from "@smile2impress/components";

import { FIELDS, Theme } from "constants/enum";
import phoneCodes from "configs/countries/phoneCodes.json";
import { Input } from "components/Input";
import { PhoneSelect } from "components/PhoneSelect";

import { locale } from "./locales";
import { RegionType, RegionTypes } from "./components/Regions";
import { PhoneRegionsInputProps } from "./PhoneRegionsInput.types";
import styles from "./PhoneRegionsInput.module.scss";

const cx = cn.bind(styles);

export const PhoneRegionsInput: FC<PhoneRegionsInputProps> = ({
    name,
    nameCountry,
    placeholder,
    errorText,
    className,
    value = "",
    disabled,
    onBlur,
    onChange,
    onSetRegion,
    mask,
    version,
    language,
    theme = Theme.DARK,
    defaultRegion = RegionTypes.GLOBAL,
    isActiveMargin,
    withoutPhone,
    ...rest
}) => {
    const { setFieldValue, validateForm } = useFormikContext();
    const inputRef = useRef<HTMLInputElement>(null);

    countries.registerLocale(locale[language]);

    const availableAlpha2Codes = Object.keys(countries.getAlpha2Codes()).filter(
        (alpha2) => phoneCodes[alpha2 as keyof typeof phoneCodes]
    );

    const regionsPhones: RegionType[] = availableAlpha2Codes.map((alpha2) => {
        const { code: phoneCode, mask: phoneMask } =
            phoneCodes[alpha2 as keyof typeof phoneCodes];

        return {
            name: countries.getName(alpha2, language),
            alpha2,
            phoneCode,
            phoneMask,
        };
    });

    const regionByDefault = regionsPhones.find(
        (region) => region.phoneCode === defaultRegion
    );
    const [currentArrowContent, setArrowContent] =
        useState<RegionType | undefined>(regionByDefault);

    const handleSwitchRegion = (region: RegionType) => {
        setArrowContent(region);

        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(() => {
        if (name && currentArrowContent) {
            setFieldValue(
                nameCountry,
                currentArrowContent.phoneCode.replace(/\\/g, "")
            );
        }
    }, [currentArrowContent, name, nameCountry, setFieldValue]);

    useEffect(() => {
        if (currentArrowContent && onSetRegion) {
            onSetRegion(currentArrowContent.phoneMask);
        }
    }, [currentArrowContent, onSetRegion]);

    useEffect(() => {
        if (currentArrowContent?.phoneMask || withoutPhone) {
            validateForm();
        }
    }, [currentArrowContent?.phoneMask, validateForm, withoutPhone]);

    return (
        <Flex className={cx(className, `theme_${theme}`)} m={0}>
            <PhoneSelect
                version={version}
                regions={regionsPhones}
                theme={theme}
                onSwitchRegion={handleSwitchRegion}
            />

            <Input
                // mask={currentArrowContent?.phoneMask}
                placeholder={placeholder}
                name={name}
                dataTestId={FIELDS.PHONE}
                ref={inputRef}
                value={value === null ? "" : value}
                type="tel"
                onBlur={onBlur}
                onChange={onChange}
                theme={theme}
                disabled={disabled}
                errorText={errorText}
                isActiveMargin={isActiveMargin}
                {...rest}
            />
        </Flex>
    );
};
