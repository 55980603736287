import { Translate } from "next-translate";

import { BookingFormContentType } from "../BookAppointmentFullForm.types";
import { FormContentProps } from "./types";
import defaultContent from "./spain";

const pageContent = (
    t: Translate,
    { version }: FormContentProps
): BookingFormContentType => defaultContent(t, { version });

export default pageContent;
