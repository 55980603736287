import { useState, useEffect, ChangeEvent } from "react";
import classNames from "classnames/bind";

import { noop } from "utils/noop";
import { RadioWithLabel } from "../RadioWithLabel";
import { RadioGroupContextProvider } from "../../context";
import { RadioGroupProps } from "./RadioGroup.types";
import styles from "./RadioGroup.module.scss";

const cx = classNames.bind(styles);

export const RadioGroup = ({
    children,
    defaultValue,
    onChange = noop,
}: RadioGroupProps) => {
    const [state, setState] = useState("");

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setState(e.target.value);
        onChange(e);
    };

    useEffect(() => {
        if (defaultValue) {
            setState(defaultValue);
        }
    }, [defaultValue]);

    return (
        <RadioGroupContextProvider
            value={{ value: state, onChange: handleOnChange }}
        >
            <div role="radiogroup" className={cx("root")}>
                {children}
            </div>
        </RadioGroupContextProvider>
    );
};

RadioGroup.RadioWithLabel = RadioWithLabel;
