import { CountryVersion } from "constants/enum";

export const linkTerms = {
    [CountryVersion.US]: "/policy/terms-and-conditions-us.pdf",
    [CountryVersion.SPAIN]: "",
    [CountryVersion.CATALAN]: "",
    [CountryVersion.FRANCE]: "",
    [CountryVersion.GERMANY]: "",
    [CountryVersion.ITALY]: "",
    [CountryVersion.PORTUGAL]: "",
    [CountryVersion.UK]: "",
    [CountryVersion.UKRAINE]: "",
};

export const linkTelehealthConsent = {
    [CountryVersion.US]: "/policy/telehealth-consent-us.pdf",
    [CountryVersion.SPAIN]: "",
    [CountryVersion.CATALAN]: "",
    [CountryVersion.FRANCE]: "",
    [CountryVersion.GERMANY]: "",
    [CountryVersion.ITALY]: "",
    [CountryVersion.PORTUGAL]: "",
    [CountryVersion.UK]: "",
    [CountryVersion.UKRAINE]: "",
};

export const linkRecordConsent = {
    [CountryVersion.US]: "/policy/record-taking-consent-us.pdf",
    [CountryVersion.SPAIN]: "",
    [CountryVersion.CATALAN]: "",
    [CountryVersion.FRANCE]: "",
    [CountryVersion.GERMANY]: "",
    [CountryVersion.ITALY]: "",
    [CountryVersion.PORTUGAL]: "",
    [CountryVersion.UK]: "",
    [CountryVersion.UKRAINE]: "",
};

export const linkPolitic = {
    [CountryVersion.SPAIN]: "/policy/privacy-policy-spanish.pdf",
    [CountryVersion.CATALAN]: "/policy/privacy-policy-spanish.pdf",
    [CountryVersion.FRANCE]: "/policy/privacy-policy-france.pdf",
    [CountryVersion.GERMANY]: "/policy/privacy-policy-germany.pdf",
    [CountryVersion.ITALY]: "/policy/privacy-policy-italy.pdf",
    [CountryVersion.PORTUGAL]: "/policy/privacy-policy-portugal.pdf",
    [CountryVersion.UK]: "/policy/privacy-policy-english.pdf",
    [CountryVersion.UKRAINE]: "/policy/privacy-policy-ukraine.pdf",
    [CountryVersion.US]: "/policy/privacy-policy-us.pdf",
};

export const linkPoliticInstagram = {
    [CountryVersion.SPAIN]: "/policy/privacy-policy-instagram-spanish-2021.pdf",
    [CountryVersion.CATALAN]:
        "/policy/privacy-policy-instagram-spanish-2021.pdf",
    [CountryVersion.FRANCE]: "/policy/privacy-policy-france.pdf",
    [CountryVersion.GERMANY]: "",
    [CountryVersion.ITALY]: "/policy/privacy-policy-instagram-italy-2021.pdf",
    [CountryVersion.PORTUGAL]:
        "/policy/privacy-policy-instagram-portugal-2021.pdf",
    [CountryVersion.UK]: "https://smile2impress.com/uk/privacy-policy",
    [CountryVersion.UKRAINE]: "https://smile2impress.com/ua/privacy-policy",
    [CountryVersion.US]: "",
};
