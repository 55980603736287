import { useState } from "react";
import classNames from "classnames/bind";

import { Radio } from "../../Radio";
import { useRadioContext } from "../../context";
import { RadioWithLabelProps } from "./RadioWithLabel.types";
import styles from "./RadioWithLabel.module.scss";

const cx = classNames.bind(styles);

export const RadioWithLabel = ({
    value,
    id,
    className,
    children,
}: RadioWithLabelProps) => {
    const [isHoverActive, setHoverActive] = useState<boolean>(false);
    const { value: currentValue, onChange } = useRadioContext();
    const checked = value === currentValue;

    const handleOnMouseEnter = () => {
        setHoverActive(true);
    };

    const handleOnMouseLeave = () => {
        setHoverActive(false);
    };

    return (
        <label
            className={cx(className, "root", { root_checked: checked })}
            htmlFor={id}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        >
            <Radio
                id={id}
                value={value}
                checked={checked}
                isHoverActive={isHoverActive}
                onChange={onChange}
            />
            {children}
        </label>
    );
};
