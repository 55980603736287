import { COOKIES, FIELDS } from "constants/enum";

export const getCookieName = (fieldName: string) => {
    switch (fieldName) {
        case FIELDS.FIRST_NAME: {
            return COOKIES.FIRST_NAME_BUFFER;
        }
        case FIELDS.LAST_NAME: {
            return COOKIES.LAST_NAME_BUFFER;
        }
        case FIELDS.EMAIL: {
            return COOKIES.EMAIL_BUFFER;
        }
        case FIELDS.PHONE: {
            return COOKIES.PHONE_BUFFER;
        }
    }
};
