import { FC, Fragment, useEffect } from "react";
import { Box } from "@smile2impress/components";

import { ClinicType } from "api/location";
import { ClinicPickerTestID } from "components/BookingWidget/components/ClinicPicker/tests/types";

import content from "./content";
import { ClinicListProps } from "./ClinicList.types";
import { ClinicBlock } from "../ClinicBlock";
import { isCombinedClinic } from "../../helpers/isHasCombinedClinics";

export const ClinicList: FC<ClinicListProps> = ({
    clinics,
    value,
    language,
    onChange,
    hasPreventSelect = true,
    isLink = false,
}) => {
    const contentByLanguage = content[language];

    const firstAdultsClinic = clinics.find(
        (clinic) =>
            clinic.clinicType === ClinicType.Adults ||
            clinic.clinicType === ClinicType.Combined
    );

    useEffect(() => {
        if (firstAdultsClinic && hasPreventSelect && !value) {
            onChange({
                id: firstAdultsClinic.id.toString(),
                type:
                    firstAdultsClinic.clinicType === ClinicType.Combined
                        ? ClinicType.Adults
                        : firstAdultsClinic.clinicType,
            });
        }
    }, [firstAdultsClinic]);

    return (
        <Box
            h={["65vh", "auto", "auto"]}
            px={1.5}
            overflowY="scroll"
            data-testid={ClinicPickerTestID.CLINIC_LIST}
        >
            {clinics.map((clinic) => {
                const isCombined = isCombinedClinic(clinic);
                const idTeens = `${clinic.id.toString()}-teens`;

                return (
                    <Fragment key={clinic.id}>
                        <Box my={2}>
                            <ClinicBlock
                                id={
                                    clinic.clinicType === ClinicType.Teens
                                        ? idTeens
                                        : clinic.id.toString()
                                }
                                checked={
                                    clinic.clinicType === ClinicType.Teens
                                        ? idTeens === value
                                        : clinic.id.toString() === value
                                }
                                title={clinic.title}
                                isLink={isLink}
                                onChange={onChange}
                                address={clinic.address}
                                type={
                                    isCombined
                                        ? ClinicType.Adults
                                        : clinic.clinicType
                                }
                            />
                        </Box>
                        {isCombined && (
                            <Box my={2}>
                                <ClinicBlock
                                    id={idTeens}
                                    checked={idTeens === value}
                                    title={`${clinic.title} ${contentByLanguage.teens}`}
                                    address={clinic.address}
                                    type={ClinicType.Teens}
                                    onChange={onChange}
                                    isLink={isLink}
                                />
                            </Box>
                        )}
                    </Fragment>
                );
            })}
        </Box>
    );
};
