import { FilterOption } from "../SelectWithSearch.types";

export const filterOption = (
    props: FilterOption,
    inputValue: string,
    labels: string[]
) => {
    const str = inputValue.toLowerCase();
    const reg = new RegExp(`^${str.replace(/[(),\\[0,?*+]/g, "")}`);
    const regTest = reg.test(props.label.toLowerCase());

    return str
        ? (regTest && !props.data.isHide) ||
              (labels.includes(props.label) && !props.data.isHide)
        : regTest;
};
