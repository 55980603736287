import { ReactElement } from "react";

export enum ModalPosition {
    FULL = "full",
    RIGHT = "right",
    BOTTOM = "bottom",
}

export interface ModalProps {
    children: ReactElement;
    onClose: () => void;

    position?: ModalPosition;
    className?: string;
    classNameBody?: string;
    showCloseButton?: boolean;
}
