import { FC } from "react";
import classnames from "classnames/bind";
import { Heading, Text } from "@smile2impress/components";

import { Radio } from "components/Radio";
import { FormTestID } from "components/BookAppointmentFullForm/tests/types/ids";

import { ClinicBlockProps } from "./ClinicBlock.types";
import styles from "./ClinicBlock.module.scss";

const cx = classnames.bind(styles);

export const ClinicBlock: FC<ClinicBlockProps> = ({
    id,
    type,
    title,
    address,
    checked,
    onChange,
    isLink,
}) => {
    const handleChange = () => {
        onChange({
            id,
            type,
        });
    };

    return (
        <label
            htmlFor={id}
            className={cx("root", {
                root_checked: checked,
            })}
            {...(isLink && { onClick: handleChange })}
        >
            {!isLink && (
                <Radio
                    name="clinic"
                    data-testid={FormTestID.CLINIC}
                    value={id}
                    id={id}
                    className={cx("radio")}
                    classNameCheckMark={cx("checkmark")}
                    onChange={handleChange}
                    checked={checked}
                />
            )}
            <Heading mb="1" textStyle="title3" as="h3">
                {title}
            </Heading>
            <Text textStyle="caption">{address}</Text>
        </label>
    );
};
