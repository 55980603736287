import { OptionProps } from "components/SelectWithSearch";

type ResultType = {
    [key: string]: {
        label: string;
        options: OptionProps[];
    };
};

export const getOptGroupByOptions = (options: OptionProps[]) =>
    options.reduce((r: ResultType, e) => {
        const label = e.label[0];
        const option = { label: e.label, value: e.value, isHide: e.isHide };

        if (!r[label]) {
            r[label] = {
                label,
                options: [option],
            };
        } else {
            r[label].options.push(option);
        }

        return r;
    }, {});
