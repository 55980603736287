import { Translate } from "next-translate";

import { CountryVersion } from "constants/enum";

import { BookingFormContentType } from "../BookAppointmentFullForm.types";
import { FormContentProps } from "./types";
import { linkPolitic, linkPoliticInstagram } from "../linksDocuments";

const getContent = (
    t: Translate,
    { version }: FormContentProps
): BookingFormContentType => ({
    cityPlaceholder: t("cityPlaceholder"),
    cityTypeError: t("cityTypeError"),
    firstNamePlaceholder: t("firstNamePlaceholder"),
    firstNameTypeError: t("firstNameTypeError"),
    lastNamePlaceholder: t("lastNamePlaceholder"),
    lastNameTypeError: t("lastNameTypeError"),
    instagramUserPlaceholder: t("instagramUserPlaceholder"),
    instagramUserTypeError: t("instagramUserTypeError"),
    nameMinError: t("nameMinError"),
    nameMaxError: t("nameMaxError"),
    phoneNumberPlaceholder: t("phoneNumberPlaceholder"),
    phoneNumberFormatError: (numberCountOfPhone) =>
        t("phoneNumberFormatError", { amount: numberCountOfPhone }),
    phoneNumberTypeError: t("phoneNumberTypeError"),
    emailPlaceholder: t("emailPlaceholder"),
    emailFormatError: t("emailFormatError"),
    emailTypeError: t("emailTypeError"),
    checkboxSpecialOffersError: t("checkboxSpecialOffersError"),
    checkboxSpecialOffersPlaceholder: t("checkboxSpecialOffersPlaceholder"),
    checkboxSpecialOffersPlaceholder2: t("checkboxSpecialOffersPlaceholder2"),
    checkboxAcceptError: t("checkboxAcceptError"),
    checkboxAcceptPlaceholder: {
        text: t("checkboxAcceptPlaceholderText"),
        link: linkPolitic[version],
        name: t("checkboxAcceptPlaceholderLink"),
        textAnd: t("checkboxAcceptPlaceholderTextAnd"),
        namePromotion: t("checkboxAcceptPlaceholderNamePromotion"),
        namePromotionEnd:
            version === CountryVersion.GERMANY
                ? t("checkboxAcceptPlaceholderNamePromotionEnd")
                : "",
        requiredFieldSign: "*",
    },
    checkboxAcceptInstagramPlaceholder: {
        text: t("checkboxAcceptInstagramPlaceholderText"),
        link: linkPoliticInstagram[version],
        name: t("checkboxAcceptInstagramPlaceholderLink"),
    },
    action: t("action"),
    titleModal: t("titleModal"),
});

export default getContent;
