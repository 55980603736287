import { languages } from "configs/languageConfig";

import france from "./france";
import italy from "./italy";
import portugal from "./portugal";
import spain from "./spain";
import uk from "./uk";
import us from "./us";
import catalan from "./catalan";
import ukraine from "./ukraine";
import germany from "./germany";

export default {
    [languages.en]: uk,
    [languages.us]: us,
    [languages.it]: italy,
    [languages.es]: spain,
    [languages.pt]: portugal,
    [languages.fr]: france,
    [languages.ca]: catalan,
    [languages.uk]: ukraine,
    [languages.de]: germany,
};
