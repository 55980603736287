import { RegionType } from "components/PhoneRegionsInput/components/Regions";
import { CountryCode } from "constants/enum";

export const getRegion = (regions: RegionType[], code: CountryCode) =>
    regions.find((region) => region.alpha2.toLowerCase() === code);

export const sortRegions = (regions: RegionType[]) => {
    return regions.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });
};
