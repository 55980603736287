import { FC } from "react";
import classNames from "classnames/bind";

import { Theme } from "constants/enum";

import { RegionsProps } from "./Regions.types";
import styles from "./Regions.module.scss";

const cx = classNames.bind(styles);

export const Regions: FC<RegionsProps> = ({
    regions,
    theme = Theme.DARK,
    getItemProps,
}) => {
    return (
        <div className={cx("root", `theme_${theme}`)}>
            {regions.map((region, index) => {
                const { name, phoneCode, alpha2 } = region;

                return (
                    <li
                        {...getItemProps({
                            item: region,
                        })}
                        key={index}
                        className={cx("item")}
                        onMouseMove={undefined}
                    >
                        <div
                            className={cx("icon", "item__icon")}
                            style={{
                                backgroundImage: `url("/images/icons/countries/${alpha2.toLowerCase()}.svg")`,
                            }}
                        />
                        <span className={cx("item-phone-code")}>
                            {phoneCode.replace(/\\/g, "")}
                        </span>
                        <span className={cx("item-name")}>{name}</span>
                    </li>
                );
            })}
        </div>
    );
};
