import { ClinicType, ClinicInfo } from "api/location";

export const isHasCombinedClinics = (clinics: ClinicInfo[]) => {
    return Boolean(
        clinics.find((clinic) => clinic.clinicType === ClinicType.Combined)
    );
};

export const isCombinedClinic = (clinic: ClinicInfo) =>
    clinic.clinicType === ClinicType.Combined;

export const filterClinicsByTypesAndTeens = (
    clinics: ClinicInfo[],
    isTeenRoute: boolean
): ClinicInfo[] => {
    if (isTeenRoute) {
        return clinics;
    }

    return clinics;
};
