import React from "react";
import classNames from "classnames/bind";

import { RadioProps } from "./Radio.types";
import styles from "./Radio.module.scss";

const cx = classNames.bind(styles);

export const Radio = ({
    className,
    classNameCheckMark,
    isHoverActive = false,
    checked,
    ...restProps
}: RadioProps) => (
    <span className={cx(className, "root")}>
        <input
            type="radio"
            className={cx("radio-input", {
                "radio-input_is-hover-active": isHoverActive,
            })}
            checked={checked}
            {...restProps}
        />
        <span
            className={cx("checkmark-input", classNameCheckMark, {
                "checkmark-input_checked": checked,
            })}
        />
    </span>
);
